<template>
  <div>
    <headers></headers>
    <div class="exam" v-if="dataEnd">
      <!-- 进度条 -->
      <div class="schedule w">
        <div class="schedule-name">错题集</div>
        <!-- <div class="schedule-bottom d-flex align-center justify-between">
          <div class="schedule-line">
            <span
              :style="{ width: (tipsNum / tips.length) * 100 + '%' }"
            ></span>
          </div>
          <div class="schedule-num">
            <span>已完成{{ tipsNum }}题</span> / 共{{ tips.length }}题
          </div>
        </div> -->
      </div>
      <!-- 内容 -->
      <div class="content w d-flex justify-between">
        <div class="left">
          <div class="left-content">
            <div
              class="question"
              v-html="listData[activIndex].qdbQuestions.dataText"
            ></div>
            <div
              class="question"
              v-html="listData[activIndex].qdbQuestions.question"
            ></div>
            <div
              class="collect d-flex align-center cursor"
              @click="collectBtn(listData[activIndex].isCollection)"
            >
              <img
                v-if="listData[activIndex].isCollection == 0"
                src="../../assets/img/ticollect.png"
                alt=""
              />
              <img
                v-if="listData[activIndex].isCollection == 1"
                src="../../assets/img/ticollect_b.png"
                alt=""
              />
              收藏
            </div>
            <div
              class="question"
              style="margin-top: 20px"
              v-html="listData[activIndex].qdbQuestions.explains"
            ></div>
          </div>
          <div
            class="bottom-btn d-flex align-center justify-between"
            v-if="dataEnd"
          >
            <div
              class="btn-item-l d-flex align-center cursor"
              @click="bindPre"
              :class="{ botton_btn_active: activIndex == 0 }"
            >
              <img
                v-show="activIndex == 0"
                src="../../assets/img/next_w.png"
                alt=""
              />
              <img
                v-show="activIndex != 0"
                src="../../assets/img/next_b.png"
                alt=""
              />
              上一题
            </div>
            <div
              class="btn-item-r d-flex align-center cursor"
              @click="bindNext"
              :class="{
                botton_btn_active: listData.length - 1 == activIndex,
              }"
            >
              下一题
              <img
                v-show="listData.length - 1 == activIndex"
                src="../../assets/img/next_w.png"
                alt=""
              />
              <img
                v-show="listData.length - 1 != activIndex"
                src="../../assets/img/next_b.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="card">
            <div class="card-name d-flex align-center justify-between">
              <div class="card-left d-flex align-center">
                <span> </span>
                答题卡
              </div>
            </div>

            <div class="card-content">
              <ul class="card-list d-flex flex-wrap">
                <li
                  class="card-item d-flex align-center justify-center cursor"
                  v-for="(item, index) in tips"
                  :key="index"
                  @click="selectTopic(index)"
                >
                  {{ index + 1 }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataEnd: false, //数据请求完成与否
      subjectId: "", //专业id
      ansInputType: "",
      source: "", //试题来源
      listData: "", //数据列表
      activIndex: 0, //当前试题
      tips: [],
      tipsNum: 0,
      name: "",
    };
  },
  mounted() {
    console.log(this.$route.query.subjectId)
    this.subjectId = this.$route.query.subjectId;
    this.exercise()
  },

  methods: {
    // 查看解析
    analytical() {
      this.isAnalytical = !this.isAnalytical;
    },
    // 收藏
    collectBtn(isCollect) {
      let _this = this;
      var activIndex = this.activIndex; // 当前试题
      var listData = this.listData; // 源数据
      var questionNum = listData[activIndex].qdbQuestions.id;
      let status;
      if (isCollect == 0) {
        status = 1;
      } else {
        status = 0;
      }
      _this.collect(questionNum, status, listData, activIndex)
      // _this.$api
      //   .collect({
      //     questionsId: questionNum,
      //     statu: status,
      //     subject: listData[activIndex].subjectId,
      //     examtype: listData[activIndex].examType
      //   })
      //   .then(res => {
      //     console.log(res);
      //     _this.exercise();
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    async collect(questionNum, status, listData, activIndex) {
      let _this = this;
      let data = {
        questionsId: questionNum,
        statu: status,
        subject: listData[activIndex].subjectId,
        examtype: listData[activIndex].examType
      }
      const res = await this.$ajaxRequest('get', 'collect', data)
      console.log(res);
      _this.exercise();
    },
    // 答题卡选择
    selectTopic(index) {
      let _this = this;
      _this.isAnalytical = false;
      _this.activIndex = index;
    },
    // 初始数据
    // exercise() {
    //   let _this = this;
    //   let data;
    //   _this.$api
    //     .showError({
    //       historyId: _this.subjectId
    //     })
    //     .then(res => {
    //       console.log(res.data);
    //       _this.listData = res.data;
    //       _this.dataEnd = true;
    //       _this.judgeDecision();
    //       if (!res.data) {
    //         this.$alert('此练习暂无试提', '提示', {
    //           confirmButtonText: '确定',
    //           callback: action => {
    //             this.$router.go(-1)
    //           }
    //         });
    //       }
    //     })
    //     .catch(err => { });
    // },
     async exercise(params) {
      let _this = this;
      let data = {
        subjectId: _this.subjectId
      }
      const res = await this.$ajaxRequest('get', 'showError', data)
      _this.listData = res.data;
          _this.dataEnd = true;
          _this.judgeDecision();
          if (!res.data) {
            this.$alert('此练习暂无试提', '提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.$router.go(-1)
              }
            });
          }
    },
    // 判断做题与否
    judgeDecision() {
      let _this = this;
      let tips = [];
      let listData = _this.listData;
      for (let i in _this.listData) {
        let item;
        item = {
          item: i,
          select: false
        }
        tips.push(item);
      }
      _this.tips = tips;
    },
    //下一题
    bindNext() {
      var _this = this;
      var activIndex = _this.activIndex; //当前题次
      var listData = this.listData;
      if (listData.length - 1 > activIndex) {
        _this.activIndex = activIndex + 1;
      }
    },
    // 上一题
    bindPre: function (e) {
      var _this = this;
      var listData = this.listData;
      var activIndex = _this.activIndex;
      if (activIndex > 0) {
        console.log(activIndex)
        _this.activIndex = activIndex - 1;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.radio_active {
  background: #0a82ff !important;
  color: #ffffff !important;
  border-color: #0a82ff !important;
}
.botton_btn_active {
  color: #999999 !important;
  cursor: not-allowed;
}
.card_item_active {
  background: #0a82ff !important;
  color: #ffffff !important;
}
.exam {
  position: relative;
}
// 面包屑
.breadcrumb {
  margin-top: 29px;
  .breadcrumb-title {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    // padding-left: 15px;
    // box-sizing: border-box;
    // border-left: 4px solid #5f2eff;
    span:first-child {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 11px;
    }
    span:last-child {
      width: 1px;
      height: 10px;
      background: #cccccc;
      border-radius: 1px;
      margin-left: 12px;
    }
  }
  .breadcrumb-item {
    font-size: 14px;
    color: #888888;
    margin-left: 12px;
  }
}
// 进度条
.schedule {
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  margin-top: 39px;
  padding: 29px 20px 20px 20px;
  box-sizing: border-box;
  .schedule-name {
    font-size: 24px;
    color: #222222;
  }
  .schedule-bottom {
    margin-top: 16px;
    .schedule-line {
      width: 980px;
      height: 10px;
      background: #f6f3ff;
      border-radius: 5px;
      span {
        height: 9px;
        background: #5f2eff;
        border-radius: 5px;
        display: block;
      }
    }
    .schedule-num {
      color: #222222;
      font-size: 14px;
      span {
        color: #5f2eff;
        font-size: 14px;
      }
    }
  }
}
// 内容
.content {
  margin-top: 17px;
  // 左边
  .left {
    width: 920px;
    .left-content {
      min-height: 490px;
      padding: 46px 31px;
      box-sizing: border-box;
      background: #ffffff;
      .question {
        font-size: 14px !important;
        color: #444444 !important;
      }
      .options {
        margin-top: 20px;
        .options-title {
          font-weight: bold;
          font-size: 20px;
        }
        .radio {
          margin-bottom: 20px;
          margin-left: 75px;
          .radio-num {
            width: 30px;
            font-size: 14px;
          }
          .radio-list {
            margin-top: 10px;
          }
          .radio-title {
            width: 18px;
            margin-right: 10px;
            line-height: 26px;
          }
          .radio-item {
            width: 26px;
            height: 26px;
            border: 1px solid #bbbbbb;
            border-radius: 50%;
            font-size: 9px;
            font-weight: bold;
            color: #bbbbbb;
            margin-right: 30px;
          }
        }
        .checkbox {
          margin-bottom: 10px;
          margin-left: 50px;
          .radio-num {
            width: 30px;
            font-size: 14px;
          }
          .radio-list {
            margin-top: 10px;
          }
          .radio-item {
            width: 18px;
            height: 18px;
            border: 1px solid #bbbbbb;
            border-radius: 50%;
            font-size: 9px;
            font-weight: bold;
            color: #bbbbbb;
            margin-right: 10px;
          }
        }
        .options-text {
          textarea {
            width: 820px;
            height: 186px;
            background: #f9f9f9;
            border: 1px solid #cccccc;
            border-radius: 6px;
            resize: none;
            padding: 13px 15px;
            box-sizing: border-box;
            margin-top: 15px;
          }
        }
      }
      .collect {
        font-size: 14px;
        color: #666666;
        margin-top: 50px;
        img {
          width: 22px;
          height: 20px;
          margin-right: 10px;
          margin-left: auto;
        }
      }
    }

    .bottom-btn {
      height: 88px;
      width: 920px;
      background: #ffffff;
      position: absolute;
      bottom: -64px;
      padding: 0 101px;
      box-sizing: border-box;
      box-shadow: 0px 0px 13px 0px rgba(180, 180, 180, 0.15);
      border-radius: 14px;
      .btn-item-l {
        font-size: 20px;
        color: #333333;
        img {
          width: 9px;
          height: 18px;
          margin-right: 10px;
        }
      }
      .btn-item-c {
        font-size: 20px;
        color: #333333;
        img {
          width: 22px;
          height: 18px;
          margin-right: 10px;
        }
      }
      .btn-item-r {
        font-size: 20px;
        color: #333333;
        img {
          width: 9px;
          height: 18px;
          margin-left: 10px;
          transform: rotate(180deg);
        }
      }
    }
  }
  // 右边
  .right {
    width: 265px;
    .operate {
      width: 265px;
      height: 86px;
      background: #ffffff;
      border-radius: 10px;
      padding: 0 70px;
      box-sizing: border-box;
      .item-img {
        width: 27px;
        height: 27px;
        margin-bottom: 11px;
      }
      .item-txt {
        font-size: 14px;
        color: #666666;
      }
      .operate-item:first-child {
        .item-img {
          width: 27px;
          height: 25px;
          margin-bottom: 11px;
        }
      }
    }
    .time {
      width: 265px;
      height: 67px;
      background: #ffffff;
      border-radius: 10px;
      padding: 0 30px;
      box-sizing: border-box;
      margin-top: 15px;
      .time-left {
        .time-img {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }
        .time-title {
          font-size: 18px;
          color: #999999;
        }
      }
      .time-right {
        width: 122px;
        font-size: 24px;
        font-weight: 500;
        color: #f53444;
        text-align: center;
      }
    }
    .card {
      width: 265px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(180, 180, 180, 0.08);
      border-radius: 10px;
      padding-bottom: 17px;
      box-sizing: border-box;
      .card-name {
        width: 100%;
        font-size: 16px;
        color: #333333;
        padding-top: 16px;
        box-sizing: border-box;
        // margin-bottom: 39px;
        .card-left {
          font-size: 16px;
          color: #333333;
          box-sizing: border-box;
          span:first-child {
            width: 4px;
            height: 17px;
            background: #5f2eff;
            border-radius: 2px;
            display: inline-block;
            margin-left: 19px;
            margin-right: 11px;
          }
          span:last-child {
            color: #5f2eff;
            font-size: 16px;
          }
        }
        .card-btn {
          font-size: 13px;
          color: #666666;
          margin-right: 22px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 7px;
          }
        }
      }
      .card-content {
        height: 300px;
        padding-right: 44px;
        box-sizing: border-box;
        overflow-y: scroll;
        margin-top: 20px;
        border-top: 1px solid #eee;
        .card-names {
          font-size: 13px;
          color: #666666;
          span {
            width: 2px;
            height: 13px;
            background: #5f2eff;
            border-radius: 1px;
            display: inline-block;
            margin-right: 17px;
          }
        }
        .card-btn {
          font-size: 13px;
          color: #666666;
          img {
            width: 14px;
            height: 14px;
            margin-right: 7px;
          }
        }
        .card-list {
          width: 100%;
          margin-left: 19px;
          margin-top: 19px;
          margin-bottom: 20px;
          .card-item {
            width: 28px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 7px;
            color: #a8a8a8;
            font-size: 13px;
            margin-right: 13px;
            margin-bottom: 10px;
            &:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }
    }
    .right-btn {
      width: 225px;
      height: 47px;
      background: #5f2eff;
      border-radius: 24px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      margin-left: 15px;
      margin-top: 30px;
    }
    .right-btn-b {
      width: 225px;
      height: 47px;
      border: 1px solid#5F2EFF;
      border-radius: 24px;
      font-size: 14px;
      font-weight: bold;
      color: #5f2eff;
      margin-left: 15px;
      margin-top: 30px;
    }
  }
}
</style>
